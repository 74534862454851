var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "removeAlphaVariables", {
    enumerable: true,
    get: function () {
      return removeAlphaVariables;
    }
  });
  function removeAlphaVariables(container, toRemove) {
    container.walkDecls(decl => {
      if (toRemove.includes(decl.prop)) {
        decl.remove();
        return;
      }
      for (let varName of toRemove) {
        if (decl.value.includes(`/ var(${varName})`)) {
          decl.value = decl.value.replace(`/ var(${varName})`, "");
        }
      }
    });
  }
  return exports;
}