var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "remapBitfield", {
    enumerable: true,
    get: function () {
      return remapBitfield;
    }
  });
  function remapBitfield(num, mapping) {
    // Create masks for the old and new bits that are set
    let oldMask = 0n;
    let newMask = 0n;
    for (let [oldBit, newBit] of mapping) {
      if (num & oldBit) {
        oldMask = oldMask | oldBit;
        newMask = newMask | newBit;
      }
    }
    // Remove all old bits
    // Set all new bits
    return num & ~oldMask | newMask;
  }
  return exports;
}