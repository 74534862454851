var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return bigSign;
    }
  });
  function bigSign(bigIntValue) {
    return (bigIntValue > 0n) - (bigIntValue < 0n);
  }
  return exports;
}